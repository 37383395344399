/* eslint-disable standard/no-callback-literal */
import { getLocalStorage } from '@/utils/localStorage'
import { vertifyBoolean } from '@/utils/validation'
const testSelect = function (rule, value, cb) {
  if (value) {
    cb()
  }
  cb(false)
}
const personStepFive = {
  namespaced: true,
  state: {
    test_file_list: JSON.parse(getLocalStorage('test_file_list')),
    is_head_of_government: vertifyBoolean(getLocalStorage('is_head_of_government')),
    is_government_minister: vertifyBoolean(getLocalStorage('is_government_minister')),
    is_court_judge: vertifyBoolean(getLocalStorage('is_court_judge')),
    is_governor_of_bank: vertifyBoolean(getLocalStorage('is_governor_of_bank')),
    is_foreign_representative: vertifyBoolean(getLocalStorage('is_foreign_representative')),
    is_armed_forces: vertifyBoolean(getLocalStorage('is_armed_forces')),
    is_chief_executive: vertifyBoolean(getLocalStorage('is_chief_executive')),
    is_relative_with_entity: vertifyBoolean(getLocalStorage('is_relative_with_entity')),
    is_ownership_of_entity: vertifyBoolean(getLocalStorage('is_ownership_of_entity')),
    confirm_checkbox: vertifyBoolean(getLocalStorage('confirm_checkbox')),
    rules: {
      is_head_of_government: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      is_government_minister: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      is_court_judge: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      is_governor_of_bank: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      is_foreign_representative: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      is_armed_forces: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      is_chief_executive: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      is_relative_with_entity: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      is_ownership_of_entity: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      confirm_checkbox: [
        { validator: testSelect, required: true, message: 'Please Select', trigger: 'change' }
      ]
    }
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      window.localStorage.setItem(payload.content, payload.value)
    },
    SetObjectMutaion (state, payload) {
      state[payload.content] = payload.value
      window.localStorage.setItem(payload.content, JSON.stringify(payload.value))
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ChangeObjectAction (content, payload) {
      content.commit('SetObjectMutaion', payload)
    }
  },
  getters: {
    GetStepFive: (state) => {
      return state
    }
  }
}
export default personStepFive
