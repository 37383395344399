// import 'ant-design-vue/dist/antd.css'
import Vue from 'vue'
import router from './router'
import store from './store'
import './style/App.css'
import i18n from './i18n'
import './plugins/antd-ui'
import './assets/css/tailwind.css'
import App from './App.vue'

router.afterEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
})
Vue.directive('focus', {
  inserted: function (el, binding) {
    if (binding.value) {
      el.focus()
    }
  }
})
Vue.directive('scroll', {
  inserted: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
