
export default {
  namespaced: true,
  state: {
    email: '',
    pwd1: '',
    pwd2: '',
    countryValue: '',
    languageValue: '',
    currencyValue: '',
    regionValue: '',
    cityValue: '',
    postalCode: '',
    phoneCode: '',
    phoneNum: ''
  },
  mutations: {
    SetState (state, payload) {
      state[payload.content] = payload.value
      // state[payload.content] = payload.value
    }
  },
  actions: {
    setStateAction (context, payload) {
      context.commit('SetState', payload)
    }
  }
}
