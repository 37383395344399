<template>
  <div class=" bg-gray-main pt-1">
    <div class=" supporters-name text-center">FINANCIAL SERVICE PROVIDER REGISTER</div>
        <div class=" supporters-number text-center">NUMBER: FSP 217725</div>
    <div class=" sm:py-6  sm:px-4  flex flex-no-wrap sm:flex-wrap justify-evenly m-auto  w-100vw px-10vw py-5vh">
      <div class="supporters-unit">
        <img
          class="supporters-img"
          src="@/assets/img/Department-of-Internal-Affairs.png"
          alt=""
        />
        <div class="supporters-name">DEPARTMENT OF INTERNAL AFFAIRS (DIA)</div>
        <div class="supporters-number">NUMBER: 3728766</div>
      </div>
      <!-- <div class="supporters-unit">
        <img
          class="supporters-img"
          src="@/assets/img/Companies-Office.png"
          alt=""
        />
        <div class="supporters-name">FINANCIAL SERVICE PROVIDER REGISTER</div>
        <div class="supporters-number">NUMBER: FSP 217725</div>
      </div> -->
      <div class="supporters-unit">
        <img class="supporters-img" src="@/assets/img/newFSCL.png" alt="" />
        <div class="supporters-name">
          FINANCIAL SERVICE COMPLAINTS LTD (FSCL)
        </div>
        <div class="supporters-number">NUMBER: FSCL 4377</div>
      </div>
      <div class="supporters-unit">
        <img class="supporters-img" src="@/assets/img/newFinsia.png" alt="" />
        <div class="supporters-name">
          FINANCIAL SERVICES INSTITUTE OF AUSTRALASIA
        </div>
        <div class="supporters-number">CERTIFIED MEMBERSHIP</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}

</script>

<style scoped>
.supporters-img{
   border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  object-fit: contain;
}
.supporters-img:hover {
  box-shadow: 0 0 2px 1px #163257;
}
</style>
