// import { getLocalStorage } from '@/utils/localStorage'
const personStepSeven = {
  namespaced: true,
  state: {
    fileList: [
    ]
  },
  mutations: {
    SetFileListMutation (state, payload) {
      state.fileList = payload
    },
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      // window.localStorage.setItem(payload.content, payload.value)
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    }
  },
  getters: {
    getOrifile: state => {
      return state
    }
  }
}
export default personStepSeven
