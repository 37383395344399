import { getLocalStorage } from '@/utils/localStorage'
const personStepTwo = {
  namespaced: true,
  state: {
    company_name: getLocalStorage('company_name'),
    company_phone_num: getLocalStorage('company_phone_num'),
    company_register_num: getLocalStorage('company_register_num'),
    company_tax_num: getLocalStorage('company_tax_num'),
    company_industry_field: getLocalStorage('company_industry_field'),
    company_field_spec: getLocalStorage('company_field_spec'),
    entity_has_other_country_tax: getLocalStorage('entity_has_other_country_tax') === true,
    entity_panes: JSON.parse(getLocalStorage('entity_panes')),
    company_po_box: getLocalStorage('company_po_box'),
    company_city: getLocalStorage('company_city'),
    company_country: getLocalStorage('company_country'),
    company_post_code: getLocalStorage('company_post_code'),
    company_addressProveType: +getLocalStorage('company_addressProveType'),
    company_bank_stament_address_file_list: getLocalStorage('company_bank_stament_address_file_list'),
    company_utility_address_file_list: getLocalStorage('company_utility_address_file_list'),
    company_government_correspondence_address_file_list: getLocalStorage('company_government_correspondence_address_file_list'),
    cpmpany_commission_correspondence_1_address_file_list: getLocalStorage('cpmpany_commission_correspondence_1_address_file_list'),
    company_tenancy_agreement_file_list: getLocalStorage('company_tenancy_agreement_file_list'),
    company_vehicle_register_address_file_list: getLocalStorage('company_vehicle_register_address_file_list'),
    company_source_of_funds_text: getLocalStorage('company_source_of_funds_text'),
    company_purpose_of_transaction_text: getLocalStorage('company_purpose_of_transaction_text'),
    company_transaction_country_list: getLocalStorage('company_transaction_country_list')
  },
  mutations: {
    SetFormMutation (state, payload) {
      console.log(payload)
      state[payload.content] = payload.value
      window.localStorage.setItem(payload.content, payload.value)
    },
    SetObjectMutaion (state, payload) {
      state[payload.content] = payload.value
      window.localStorage.setItem(payload.content, JSON.stringify(payload.value))
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ChangeObjectAction (content, payload) {
      content.commit('SetObjectMutaion', payload)
    }
  },
  getters: {
    GetStepOne: state => {
      // if (state.company_addressProveType === 1) {
      //   delete state.company_utility_address_file_list
      //   delete state.company_utility_address_file_list
      //   delete state.company_utility_address_file_list
      //   delete state.company_utility_address_file_list
      // }
      return state
    }
  }
}
export default personStepTwo
