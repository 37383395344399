
import axios from 'axios'
const countrySelection = {
  namespaced: true,
  state: {
    countryList: []
  },
  mutations: {
    SetCountryList (state, payload) {
      state.countryList = payload
    }
  },
  actions: {
    FetchDataAction (context, payload) {
      axios({
        method: 'post',
        url: 'https://fx.iemoney.co.nz/api/v5/tax-country'
      }).then((res) => {
        context.commit('SetCountryList', res)
      })
    }
  },
  getters: {
    popularCountryList: state => {
      const whiteList = ['AU', 'CN', 'US', 'NZ']
      return state.countryList.filter(({ code }) => whiteList.includes(code))
    },
    allCountryList: state => {
      const whiteList = ['AU', 'CN', 'US', 'NZ']
      return state.countryList.filter(({ code }) => !whiteList.includes(code))
    }
  }
}
export default countrySelection
