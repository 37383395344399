<template>
  <div>
    <div class="mask" @click="handleMaskClick"></div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    isShow: Boolean
  },
  components: {
  },
  methods: {
    handleMaskClick () {
      this.$emit('MaskToggle')
    }
  }
}

</script>

<style scoped>
.mask {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
