import { vertifyBoolean } from '@/utils/validation'
import { getLocalStorage } from '@/utils/localStorage'
const personStepTwo = {
  namespaced: true,
  state: {
    source_of_funds_text: getLocalStorage('source_of_funds_text') || undefined,
    purpose_of_transaction_text: getLocalStorage('purpose_of_transaction_text') || undefined,
    transaction_country_list: getLocalStorage('transaction_country_list'),
    num_of_anual_transactions: getLocalStorage('num_of_anual_transactions'),
    frequency_of_transaction: getLocalStorage('frequency_of_transaction'),
    total_volumn_of_trasaction_per_year: getLocalStorage('total_volumn_of_trasaction_per_year'),
    average_volume_of_per_transaction: getLocalStorage('average_volume_of_per_transaction'),
    is_money_from_high_risk_country: vertifyBoolean(getLocalStorage('is_money_from_high_risk_country')),
    money_from_high_risk_country_specific: getLocalStorage('money_from_high_risk_country_specific'),
    had_trade_with_high_rish_country: vertifyBoolean(getLocalStorage('had_trade_with_high_rish_country')),
    trade_with_high_rish_country_specific: getLocalStorage('trade_with_high_rish_country_specific'),
    is_pay_for_thrid_party: vertifyBoolean(getLocalStorage('is_pay_for_thrid_party')),
    pay_for_thrid_party_specific: getLocalStorage('pay_for_thrid_party_specific'),
    rules: {
      source_of_funds_text: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      purpose_of_transaction_text: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      transaction_country_list: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      num_of_anual_transactions: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      frequency_of_transaction: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      total_volumn_of_trasaction_per_year: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      average_volume_of_per_transaction: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      is_money_from_high_risk_country: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      money_from_high_risk_country_specific: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      had_trade_with_high_rish_country: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      trade_with_high_rish_country_specific: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      is_pay_for_thrid_party: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      pay_for_thrid_party_specific: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }]
    }
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      window.localStorage.setItem(payload.content, payload.value)
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    }
  },
  getters: {
    GetStepTwo: (state) => {
      return state
    }
  }
}
export default personStepTwo
