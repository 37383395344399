<template>
  <div :class="[isBgBlue ? 'bg-blue-main':'bg-white']">
      <div class="w-full sm:w-1440px m-auto block sm:flex sm:pt-105px sm:pr-118px sm:pb-111px sm:pl-44px p-5vw">
        <img
          class="w-full h-auto sm:w-672px sm:h-376px"
          src="@/assets/img/img_about.png"
          alt=""
        />
        <div :class="[isBgBlue ? 'text-white':'text-blue-main']">
          <div class="  hidden sm:block text-2xl sm:text-24px font-600 uppercase text-orange-main">About IE MONEY</div>
          <div class="font-400 lead sm:leading-56px sm:mt-20px
          text-2xl sm:text-48px leading-3xl noto mt-2vw
          ">
            IE MONEY - Saving you time, money and worries
          </div>
          <div class="text-875rem sm:text-18px leading-24px noto  sm:mt-48px mt-3vw">
            IE MONEY is a NZ based finance company helping customers send their
            money to where they need it for the past 6 years.
          </div>
          <div class=" text-875rem sm:text-18px  sm:leading-24px noto mt-5vw sm:mt-35px">
            We are committed to continuously improve our quality of service to
            offer better rates and more professional insights to give you the
            best value for your money.
          </div>
          <div class=" text-875rem sm:text-18px leading-24px noto mt-5vw sm:mt-35px">
            Interested in joining us? Please visit IEFS careers to see our
            vacant positions.
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    isBgBlue: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
</style>
