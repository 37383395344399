/* eslint-disable */
import { getLocalStorage } from '@/utils/localStorage'
import { vertifyBoolean } from '@/utils/validation'
const testSelect = function(rule,value,cb) {
  if(value){
    cb()
  }
  cb(false)
}
const testSign =function(rule,value,cb) {
  if(value){
    cb()
  }
  cb(false)
}
const personStepFour = {
  namespaced: true,
  state: {
    term_condition_check: vertifyBoolean(getLocalStorage('term_condition_check')),
    acknowledement_check: vertifyBoolean(getLocalStorage('acknowledement_check')),
    crs_announcement_check: vertifyBoolean(getLocalStorage('crs_announcement_check')),
    person_signature: getLocalStorage('person_signature'),
    rules: {
      term_condition_check: [
        { validator: testSelect, required: true, message: 'Please read and tick ', trigger: 'change' }
      ],
      crs_announcement_check: [
        { validator: testSelect, required: true, message: 'Please read and tick ', trigger: 'change' }
      ],
      acknowledement_check: [
        { validator: testSelect, required: true, message: 'Please read and tick ', trigger: 'change' }
      ],
      person_signature: [
        { validator: testSign, required: true, message: 'Please Sign ', trigger: 'blur' }
      ]
    }
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      window.localStorage.setItem(payload.content, payload.value)
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    }
  },
  getters: {
    GetStepFour: (state) => {
      return state
    }
  }
}
export default personStepFour
