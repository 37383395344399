
export default {
  namespaced: true,
  state: {
    isMaskShow: false
  },
  mutations: {
    toggleMask (state) {
      state.isMaskShow = !state.isMaskShow
    },
    hideMask (state) {
      state.isMaskShow = false
    }
  },
  actions: {
    toggleMaskAction (context) {
      context.commit('toggleMask')
    }
  }
}
