import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/index.vue'
import IeMoney from '@/views/home/subPage/IeMoney.vue'
import { getCustomerTransaction } from '@/api/root'
// import { Message } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    name: 'homePage',
    redirect: '/iemoney',
    children: [
      {
        path: 'iemoney',
        component: IeMoney,
        name: 'iemoney'
      },
      {
        path: 'about',
        component: () => import('@/views/home/subPage/About.vue'),
        name: 'about'
      },
      {
        path: 'affiliates',
        component: () => import('@/views/home/subPage/Affiliates.vue'),
        name: 'Affiliates'
      },
      {
        path: 'affiliates-form',
        component: () => import('@/views/home/subPage/AffiliatesForm.vue'),
        name: 'AffiliatesForm'
      },
      {
        path: 'contact',
        component: () => import('@/views/home/subPage/Contact.vue'),
        name: 'Contact'
      },
      {
        path: 'faq',
        component: () => import('@/views/home/subPage/FAQ.vue'),
        name: 'FAQ'
      },
      {
        path: 'account',
        component: () => import('@/views/home/subPage/MyAccount.vue'),
        name: 'account'
      },
      {
        path: 'products-solutions',
        component: () => import('@/views/home/subPage/Products.vue'),
        name: 'Products'
      },
      {
        path: 'login',
        component: () => import('@/views/home/subPage/LogIn.vue'),
        name: 'LogIn'
      },
      {
        path: 'signup',
        component: () => import('@/views/home/subPage/SignUp.vue'),
        name: 'SignUp'
      }
    ]
  },
  {
    path: '/signpage',
    component: () => import('@/views/SignPage.vue'),
    name: 'SignPage'
  },
  {
    path: '/user',
    component: () => import('@/views/user/index.vue'),
    name: 'UserPage',
    children: [
      {
        path: 'account',
        component: () => import('@/views/user/subPage/Account.vue'),
        name: 'Account',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'vertify',
        component: () => import('@/views/user/subPage/Vertify.vue'),
        name: 'Veritify',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'bank',
        component: () => import('@/views/user/subPage/Bank.vue'),
        name: 'Bank',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'profile',
        component: () => import('@/views/user/subPage/Profile.vue'),
        name: 'Profile',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'deposite',
        component: () => import('@/views/user/subPage/Deposite.vue'),
        name: 'Deposite',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'promote',
        component: () => import('@/views/user/subPage/Promote.vue'),
        name: 'Promote',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'transition',
        component: () => import('@/views/user/subPage/Transition.vue'),
        name: 'Transition',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'updatelevel',
        component: () => import('@/views/user/subPage/UpdateLevel.vue'),
        name: 'UpdateLevel',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'liverate',
        component: () => import('@/views/user/subPage/LiveRate.vue'),
        name: 'LiveRate',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'createorder',
        component: () => import('@/views/user/subPage/CreateOrder.vue'),
        name: 'CreateOrder',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'dashboard',
        component: () => import('@/views/user/subPage/DashBoard.vue'),
        name: 'DashBoard',
        meta: {
          roles: ['user']
        }
      }
    ]
  },
  {
    path: '/user/personapplication',
    component: () => import('@/views/user/subPage/PersonApply.vue'),
    name: 'PersonApplication',
    children: [
      {
        path: '1',
        component: () => import('@/views/user/subPage/PersonApply/StepOne'),
        name: 'PersonApplicationOne'
      },
      {
        path: '2',
        component: () => import('@/views/user/subPage/PersonApply/StepTwo'),
        name: 'PersonApplicationTwo'
      }, {
        path: '3',
        component: () => import('@/views/user/subPage/PersonApply/StepThree'),
        name: 'PersonApplicationThree'
      }, {
        path: '4',
        component: () => import('@/views/user/subPage/PersonApply/StepFour'),
        name: 'PersonApplicationFour'
      }, {
        path: '5',
        component: () => import('@/views/user/subPage/PersonApply/StepFive'),
        name: 'PersonApplicationFive'
      }
    ]
  },
  {
    path: '/user/entityapplication',
    component: () => import('@/views/user/subPage/EntityApply.vue'),
    name: 'EntityApplication',
    children: [
      {
        path: '1',
        component: () => import('@/views/user/subPage/EntityApply/StepOne'),
        name: 'EntityApplicationOne'
      },
      {
        path: '2',
        component: () => import('@/views/user/subPage/EntityApply/StepTwo'),
        name: 'EntityApplicationTwo'
      }, {
        path: '3',
        component: () => import('@/views/user/subPage/EntityApply/StepThree'),
        name: 'EntityApplicationThree'
      }, {
        path: '4',
        component: () => import('@/views/user/subPage/EntityApply/StepFour'),
        name: 'EntityApplicationFour'
      }, {
        path: '5',
        component: () => import('@/views/user/subPage/EntityApply/StepFive'),
        name: 'EntityApplicationFive'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.roles && to.meta.roles.includes('user')) {
    console.log('need permission')
    if (!localStorage.getItem('token')) {
      // console.log('local has no token')
      // Message({
      //   showClose: true,
      //   message: 'Please login first',
      //   type: 'error'
      // })
      next('/login')
    } else {
      // console.log('local has  token')
      getCustomerTransaction().then(res => console.log(res)).then(next())
    }
  } else {
    // console.log('dont need permission')
    next()
  }
})
export default router
