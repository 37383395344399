import Vue from 'vue'
import Vuex from 'vuex'
import mask from './modules/mask'
import login from './modules/login'
import signup from './modules/signup'
import countrySelection from './modules/countrySelection'
import personStepOne from './modules/personData/stepOne'
import personStepTwo from './modules/personData/stepTwo'
import personStepThree from './modules/personData/stepThree'
import personStepFour from './modules/personData/stepFour'
import personStepFive from './modules/personData/stepFive'
import personStepSix from './modules/personData/stepSix'
import personStepSeven from './modules/personData/stepSeven'
import entityStepOne from './modules/entityData/stepOne'
import entityStepTwo from './modules/entityData/stepTwo'
import entityStepThree from './modules/entityData/stepThree'
import entityStepFour from './modules/entityData/stepFour'
import entityStepFive from './modules/entityData/stepFive'
import layout from './modules/layout'
import { getRealtimeRateTable } from '@/api/root'
import { getAvailableCurrency } from '@/api/common'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    realRates: [],
    availableCurrency: {},
    isMobile: false,
    canScroll: true
  },
  mutations: {
    SetRealEXRates (state, payload) {
      state.realRates = payload
    },
    SetAvailableCurrency (state, payload) {
      state.availableCurrency = payload
    },
    SetIsMobile (state, payload) {
      state.isMobile = payload
    },
    SetScroll (state, payload) {
      state.canScroll = payload
    }
  },
  actions: {
    GetRealEXRatesAction (context, payload) {
      getRealtimeRateTable().then((res) =>
        context.commit('SetRealEXRates', res)
      )
    },
    GetavailableCurrencyAction (context) {
      getAvailableCurrency().then((res) => {
        context.commit('SetAvailableCurrency', res)
      })
    }
  },
  getters: {
    getFileList: ({ personStepSeven: { fileList } }) => {
      return fileList
    },
    getStyle: (state) => {

    },
    getAllPersonFormData: (state, getters) => {
      return getters['personStepOne/GetStepOne']
      // return { ...getters['personStepThree/GetStepThree'], ...getters['personStepOne/GetStepOne'] }
    },
    // getAllPersonFormData: ({ personStepOne, personStepTwo, personStepThree, personStepFour, personStepFive }) => {
    //   return { ...personStepOne, ...personStepTwo, ...personStepThree, ...personStepFour, ...personStepFive }
    // },
    availableCurrencyList: state => {
      return Object.values(state.availableCurrency)
    },
    availableCurrencyListShortCode: state => {
      return Object.values(state.availableCurrency).map(item => item.shortCode)
    }
  },
  modules: {
    mask,
    login,
    signup,
    personStepOne,
    personStepTwo,
    personStepThree,
    personStepFour,
    personStepFive,
    personStepSix,
    personStepSeven,
    entityStepOne,
    entityStepTwo,
    entityStepThree,
    entityStepFour,
    entityStepFive,
    countrySelection,
    layout
  }
})
