<template>
  <div class="bot-banner-container">
    <img class="bot-banner-logo" src="@/assets/img/IE-Money-logo-white.png" alt="" />
    <div class="routerLink">
      <span class="jumpItem" @click="$router.push('/products-solutions')"
        >Products & Solutions</span
      >
      <span class="divider">|</span>
      <span class="jumpItem" @click="$router.push('/affiliates')"
        >Affiliates</span
      >
      <span class="divider">|</span>
      <span class="jumpItem" @click="$router.push('/contact')">Contact</span>
      <span class="divider">|</span>
      <span class="jumpItem" @click="$router.push('/faq')">FAQ </span>
    </div>
    <div class="contactWay">
      0800 377 366 <span class="divider">|</span> +64 9 302 3246
      <span class="divider">|</span> info@iemoney.co.nz
    </div>
    <div class="copyRight">
      <span class="opacity50"
        >IE MONEY LTD, {{getThisYear}} © All Rights Reserved.</span
      >
      <span class="term">Privacy Policy | Terms of Service</span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    getThisYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
.bot-banner-container {
  height: 270px;
  background: #163257;
  padding-top: 42px;
  box-sizing: border-box;
}
.bot-banner-logo {
  display: block;
  margin: 0 auto;
  width: 250;
  height: 41px;
}
.routerLink {
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.divider {
  margin: 0 24px;
}
.contactWay {
  margin-top: 14px;
  opacity: 0.5;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}
.copyRight {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  align-items: center;
}
.term {
  color: #dbb76b;
  margin-left: 16px;
}
.opacity50 {
  opacity: 0.5;
  color: #ffffff;
}
.jumpItem {
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .bot-banner-container {
  height: auto;
  background: #163257;
  padding-top: 5vh;
  padding-bottom: 5vh;

}
.bot-banner-logo {
  display: block;
  margin: 0 auto;
  width: 40vw;
  height: auto;
}
.routerLink {
  margin-top: 5vw;
  font-size: 0.75rem;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.divider {
  margin: 0 2vw;
}
.contactWay {
  margin-top: 3vw;
  opacity: 0.5;
  font-size: 0.75rem;
  color: #ffffff;
  text-align: center;
}
.copyRight {
  margin-top: 3vw;
  font-size: 0.75rem;
  text-align: center;
  align-items: center;
}
.term {
  color: #dbb76b;
  margin-left: 0;
  display: block;
}
}
</style>
