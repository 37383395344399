import { getLocalStorage } from '@/utils/localStorage'
const login = {
  namespaced: true,
  state: {
    user: JSON.parse(getLocalStorage('user')),
    token: getLocalStorage('token'),
    hasLogIn: false
  },
  mutations: {
    SetToken (state, payload) {
      state.token = payload
      window.localStorage.setItem('token', payload)
    },
    clearToken (state) {
      state.token = ''
      window.localStorage.setItem('token', '')
    },
    SetHasLogIn (state) {
      state.hasLogIn = true
    },
    SetUserDetailProfile (state, payload) {
      state.user = payload
    }
  },
  actions: {
    SetTokenAction (context, payload) {
      return new Promise((resolve) => {
        context.commit('SetToken', payload)
        resolve()
      })
    },

    SetHasLogInAction (context) {
      context.commit('SetHasLogIn')
    },
    setUserDetailProfileAction (context, payload) {
      return new Promise((resolve) => {
        context.commit('SetUserDetailProfile', payload)
        resolve()
      })
    }
  }
}
export default login
