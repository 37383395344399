import { getLocalStorage } from '@/utils/localStorage'
const personStepSix = {
  namespaced: true,
  state: {
    purpose_of_trading: getLocalStorage('purpose_of_trading') || undefined,
    source_of_funds: getLocalStorage('source_of_funds') || undefined
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      window.localStorage.setItem(payload.content, payload.value)
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    }
  }
}
export default personStepSix
