<template>
  <div class=" flex-shrink-0 mb-4vw sm:mb-25px mr-6vw sm:mr-48px">
    <img class="w-10vw sm:w-48px sm:h-48px" :src="getImgUrl" alt="">
    <div class=" text-sm sm:text-14px text-gray-#666 mt-14px text-center">{{flag}}</div>
  </div>
</template>

<script>
export default {
  props: {
    flag: {
      type: String,
      default: 'USA'
    }
  },
  computed: {
    getImgUrl () {
      return require(`@/assets/img/${this.flag}.png`)
    }
  }
}
</script>

<style>

</style>
