<template>
  <div id="app" :class="canScroll?'can_scroll':'ban_scroll'">
      <router-view />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  data () {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  created () {
    this.GetavailableCurrencyAction()
  },
  mounted () {
    if (this.screenWidth <= 768) {
      this.screenWidthAction(this.screenWidth)
    }
    window.onresize = () => {
      this.screenWidth = document.body.clientWidth
      this.screenWidthAction(this.screenWidth)
    }

    if (this._isMobile()) {
      this.updateIsMobile(true)
    } else {
      this.updateIsMobile(false)
    }
  },

  computed: {
    ...mapState(['isMaskShow', 'canScroll'])
  },
  methods: {
    ...mapActions(['GetavailableCurrencyAction']),
    ...mapActions('layout', ['screenWidthAction']),
    ...mapMutations({ updateIsMobile: 'SetIsMobile' }),
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}

</script>
<style>
body {
  margin: 0;
}
.can_scroll{
  overflow: auto;
}
.ban_scroll{
  overflow: hidden;
}
</style>
