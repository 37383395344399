/* eslint-disable */

import { getLocalStorage } from '@/utils/localStorage'
import { vertifyBoolean } from '@/utils/validation'
const testFile = function (rule, value, cb) {
  if (value) {
    if (value.length <= 0) {
      cb(false)
    } else {
      cb()
    }
  } else {
    cb(false)
  }
}
const testPane = function(rule, value, cb) {
  if (value) {
    for(i=0;i<value.length;i++){
      console.log(i)
      // const {other_tax_contry, other_tax_num} = value[i].content
      if(other_tax_contry === '' || other_tax_num === ''){
        cb(false)
      }else{
        cb()
      }
    }
  } else {
    cb(false)
  }
}
const panesValidator = { validator: testPane, required: true, message: 'Please fill in the correct value', trigger: 'change' }

const fileValidator = { validator: testFile, required: true, message: 'pleas upload correct file', trigger: 'change' }
const personStepOne = {
  namespaced: true,
  state: {
    test: getLocalStorage('test') || undefined,
    first_name: getLocalStorage('first_name') || undefined,
    last_name: getLocalStorage('last_name') || undefined,
    email_address: getLocalStorage('email_address') || undefined,
    title: getLocalStorage('title') || undefined,
    phone_number: getLocalStorage('phone_number') || undefined,
    gender: getLocalStorage('gender') || undefined,
    date_of_birth: getLocalStorage('date_of_birth'),
    place_of_birth: getLocalStorage('place_of_birth'),
    passport_num: getLocalStorage('passport_num'),
    nationality: getLocalStorage('nationality'),
    passport_expiry: getLocalStorage('passport_expiry'),
    nationID_driver_license_num: getLocalStorage('nationID_driver_license_num'),
    driver_license_version: getLocalStorage('driver_license_version'),
    is_upload_passport: vertifyBoolean(getLocalStorage('is_upload_passport')),
    is_upload_nationalID: vertifyBoolean(getLocalStorage('is_upload_nationalID')),
    is_upload_driver_license_bank_card: vertifyBoolean(getLocalStorage('is_upload_passport')),
    is_nz_tax_resident: vertifyBoolean(getLocalStorage('is_nz_tax_resident')),
    nz_ird_num: getLocalStorage('nz_ird_num'),
    live_city: getLocalStorage('live_city'),
    live_country: getLocalStorage('live_country'),
    post_code: getLocalStorage('post_code'),
    addressProveType: +getLocalStorage('addressProveType'),
    employer_company_name: getLocalStorage('employer_company_name'),
    employer_company_address: getLocalStorage('employer_company_address'),
    po_box_address: getLocalStorage('po_box_address'),
    employer_company_city: getLocalStorage('employer_company_city'),
    employer_company_country: getLocalStorage('employer_company_country'),
    employer_company_post_code: getLocalStorage('employer_company_post_code'),
    employer_job_title: getLocalStorage('employer_job_title'),
    employer_phone_number: getLocalStorage('employer_phone_number'),
    nz_au_passport_file_list: JSON.parse(getLocalStorage('nz_au_passport_file_list')),
    cn_passport_file_list: JSON.parse(getLocalStorage('cn_passport_file_list')),
    cn_identify_front_file_list: JSON.parse(getLocalStorage('cn_identify_front_file_list')),
    cn_identify_back_file_list: JSON.parse(getLocalStorage('cn_identify_back_file_list')),
    other_identify_file_list: JSON.parse(getLocalStorage('other_identify_file_list')),
    cn_bank_file_list: JSON.parse(getLocalStorage('cn_bank_file_list')),
    passport_file_list: JSON.parse(getLocalStorage('passport_file_list')),
    nationalID_file_list: JSON.parse(getLocalStorage('nationalID_file_list')),
    has_other_country_tax: JSON.parse(getLocalStorage('has_other_country_tax')),
    driver_license_file_list: JSON.parse(getLocalStorage('driver_license_file_list')),
    bank_stament_address_file_list: JSON.parse(getLocalStorage('bank_stament_address_file_list')),
    utility_address_file_list: JSON.parse(getLocalStorage('utility_address_file_list')),
    cn_passport_visa_file_list: JSON.parse(getLocalStorage('cn_passport_visa_file_list')),
    other_passport_visa_file_list: JSON.parse(getLocalStorage('other_passport_visa_file_list')),
    government_correspondence_address_file_list: JSON.parse(getLocalStorage('government_correspondence_address_file_list')),
    commission_correspondence_address_file_list: JSON.parse(getLocalStorage('commission_correspondence_address_file_list')),
    tenancy_agreement_address_file_list: JSON.parse(getLocalStorage('tenancy_agreement_address_file_list')),
    vehicle_register_address_file_list: JSON.parse(getLocalStorage('vehicle_register_address_file_list')),
    prove_channel: getLocalStorage('prove_channel'),
    panes: JSON.parse(getLocalStorage('panes')) || [{ title: 'Tax Number 税务信息 # 1', content: { other_tax_contry: '', other_tax_num: '' }, key: 0, closable: false }],
    face_to_face_type: +getLocalStorage('face_to_face_type'),
    none_face_to_face_type: +getLocalStorage('none_face_to_face_type'),
    nz_au_driver_front_file_list: JSON.parse(getLocalStorage('nz_au_driver_front_file_list')),
    nz_au_driver_back_file_list: JSON.parse(getLocalStorage('nz_au_driver_back_file_list')),
    cn_bank_front_file_list: JSON.parse(getLocalStorage('cn_bank_front_file_list')),
    cn_bank_back_file_list: JSON.parse(getLocalStorage('cn_bank_back_file_list')),
    cn_passport_not_required_file_list: JSON.parse(getLocalStorage('cn_passport_not_required_file_list')),
    cn_passport_not_required_visa_file_list: JSON.parse(getLocalStorage('cn_passport_not_required_visa_file_list')),
    rules: {
      employer_job_title: [
        { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
      ],
      employer_company_name: [
        { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
      ],
      addressProveType: [
        { pattern: /^[1-6]$/, required: true, message: 'Please fill in the correct value', trigger: 'blur' }
      ],
      post_code: [
        { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
      ],
      live_country: [
        { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
      ],
      po_box_address: [
        { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
      ],
      has_other_country_tax: [
        { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
      ],
      nz_ird_num: [
        { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
      ],
      none_face_to_face_type: [{ pattern: /^[1-3]$/, required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      face_to_face_type: [{ pattern: /^[1-3]$/, required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      first_name: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      last_name: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      email_address: [
        { required: true, message: 'Please fill in the correct value', trigger: 'blur' },
        { required: true, pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: 'Please input  correct email', trigger: 'blur' }],
      nationID_driver_license_num: [
        { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
      ],
      prove_channel: [
        { required: true, message: 'Please select the correct option', trigger: 'blur' }
      ],
      title: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      phone_number: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      gender: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      date_of_birth: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      place_of_birth: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      passport_num: [{ required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
      nationality: [{ required: true, message: 'Please selected nationality', trigger: 'blur' }],
      is_nz_tax_resident: [
        { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
      ],
      live_city: [
        { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
      ],
      bank_stament_address_file_list: [
        { ...fileValidator }
      ],
      utility_address_file_list: [
        { ...fileValidator }
      ],
      government_correspondence_address_file_list: [
        { ...fileValidator }
      ],
      commission_correspondence_address_file_list: [
        { ...fileValidator }
      ],
      tenancy_agreement_address_file_list: [
        { ...fileValidator }
      ],
      vehicle_register_address_file_list: [
        { ...fileValidator }
      ],
      nz_au_passport_file_list: [
        { ...fileValidator }
      ],
      cn_passport_file_list: [
        { ...fileValidator }
      ],
      cn_passport_visa_file_list: [
        { ...fileValidator }
      ],
      cn_identify_front_file_list: [
        { ...fileValidator }
      ],
      cn_identify_back_file_list: [
        { ...fileValidator }
      ],
      other_identify_file_list: [
        { ...fileValidator }
      ],
      other_passport_visa_file_list:[
        { ...fileValidator }
      ],
      nz_au_driver_front_file_list:[
        { ...fileValidator }
      ],
      nz_au_driver_back_file_list:[
        { ...fileValidator }
      ],
      cn_bank_front_file_list:[
        { ...fileValidator }
      ],
      cn_bank_back_file_list:[
        { ...fileValidator }
      ],
      panes:[
        { ...panesValidator }
      ]
    }
    // panes: [{ title: 'Tax Number 税务信息 # 1', content: { other_tax_contry: '', other_tax_num: '' }, key: 0, closable: false }]
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      window.localStorage.setItem(payload.content, payload.value)
    },
    SetObjectMutaion (state, payload) {
      state[payload.content] = payload.value
      window.localStorage.setItem(payload.content, JSON.stringify(payload.value))
    }
  },
  actions: {
    ChangeFormAction (content, payload) {
      content.commit('SetFormMutation', payload)
    },
    ChangeObjectAction (content, payload) {
      content.commit('SetObjectMutaion', payload)
    }
  },

  getters: {
    GetStepOne: (state, getters) => {
      switch (state.addressProveType) {
        case 1:
          delete state.utility_address_file_list
          delete state.government_correspondence_address_file_list
          delete state.commission_correspondence_address_file_list
          delete state.tenancy_agreement_address_file_list
          delete state.vehicle_register_address_file_list
          break
        case 2:
          delete state.bank_stament_address_file_list
          delete state.government_correspondence_address_file_list
          delete state.commission_correspondence_address_file_list
          delete state.tenancy_agreement_address_file_list
          delete state.vehicle_register_address_file_list
          break
        case 3:
          delete state.bank_stament_address_file_list
          delete state.utility_address_file_list
          delete state.commission_correspondence_address_file_list
          delete state.tenancy_agreement_address_file_list
          delete state.vehicle_register_address_file_list
          break
        case 4:
          delete state.bank_stament_address_file_list
          delete state.utility_address_file_list
          delete state.government_correspondence_address_file_list
          delete state.tenancy_agreement_address_file_list
          delete state.vehicle_register_address_file_list
          break
        case 5:
          delete state.bank_stament_address_file_list
          delete state.utility_address_file_list
          delete state.government_correspondence_address_file_list
          delete state.commission_correspondence_address_file_list
          delete state.vehicle_register_address_file_list
          break
        case 6:
          delete state.bank_stament_address_file_list
          delete state.utility_address_file_list
          delete state.government_correspondence_address_file_list
          delete state.commission_correspondence_address_file_list
          delete state.tenancy_agreement_address_file_list
          break
      }
      return state
    }
  }
}
export default personStepOne
