/* eslint-disable */
import { getLocalStorage } from '@/utils/localStorage'
import { vertifyBoolean } from '@/utils/validation'
const testSign = function (rule, value, cb) {
  if (value) {
    cb()
  }else{
    cb(false)
  }
}
const fileValidator = { validator: testFile, required: true, message: 'pleas upload correct file', trigger: 'change' }
const testFile = function(rule, value, cb) {
  if (value) {
    if(value.length <= 0){
      console.log('ss');
      cb(false)
    }else{
      cb()
    }
  }else{
    cb(false)
  }
}
const personStepThree = {
  namespaced: true,
  state: {
    has_authorize_other: vertifyBoolean(getLocalStorage('has_authorize_other')),
    authorize_title: getLocalStorage('authorize_title') || undefined,
    authorize_first_name: getLocalStorage('authorize_first_name') || undefined,
    authorize_last_name: getLocalStorage('authorize_last_name') || undefined,
    authorize_gender: getLocalStorage('authorize_gender'),
    authorize_date_of_birth: getLocalStorage('authorize_date_of_birth'),
    authorize_place_of_birth: getLocalStorage('authorize_place_of_birth'),
    authorize_nationality: getLocalStorage('authorize_nationality'),
    authorize_tax_country: getLocalStorage('authorize_tax_country'),
    relation_with_authorize: getLocalStorage('relation_with_authorize'),
    authorize_residential_address: getLocalStorage('authorize_residential_address'),
    authorize_city: getLocalStorage('authorize_city'),
    authorize_country: getLocalStorage('authorize_country'),
    authorize_postcode: getLocalStorage('authorize_postcode'),
    authorize_home_telephone: getLocalStorage('authorize_home_telephone'),
    authorize_mobile_phone: getLocalStorage('authorize_mobile_phone'),
    authorize_email_address: getLocalStorage('authorize_email_address'),
    authorize_id_prove_file_list: JSON.parse(getLocalStorage('authorize_id_prove_file_list')),
    authorize_address_prove_file_list: JSON.parse(getLocalStorage('authorize_address_prove_file_list')),
    author_signature: getLocalStorage('author_signature'),
    authorize_id_type: +getLocalStorage('authorize_id_type'),
    authorize_passport_file_list: JSON.parse(getLocalStorage('authorize_passport_file_list')),
    authorize_passport_visa_file_list: JSON.parse(getLocalStorage('authorize_passport_visa_file_list')),
    authorize_cn_identify_front_file_list: JSON.parse(getLocalStorage('authorize_cn_identify_front_file_list')),
    authorize_cn_identify_back_file_list: JSON.parse(getLocalStorage('authorize_cn_identify_back_file_list')),
    authorize_nz_driver_front_file_list: JSON.parse(getLocalStorage('authorize_nz_driver_front_file_list')),
    authorize_nz_driver_back_file_list: JSON.parse(getLocalStorage('authorize_nz_driver_back_file_list')),
    authorize_nz_bank_card_front_file_list: JSON.parse(getLocalStorage('authorize_nz_bank_card_front_file_list')),
    authorize_nz_bank_card_back_file_list: JSON.parse(getLocalStorage('authorize_nz_bank_card_back_file_list')),
    authorize_nz_bank_statement_ird_file_list: JSON.parse(getLocalStorage('authorize_nz_bank_statement_ird_file_list')),
    rules: {
      author_signature: [
        { validation:testSign,  required: true, message: 'Please Sign', trigger: 'change' }
      ],
      has_authorize_other: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_title: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_first_name: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_last_name: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_gender: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_date_of_birth: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_place_of_birth: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_nationality: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_tax_country: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      relation_with_authorize: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_residential_address: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_city: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_country: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_postcode: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_home_telephone: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_mobile_phone: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_id_type: [
        {pattern: /^[1-4]$/, required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_email_address: [
        { required: true, message: 'Please Select', trigger: 'change' }
      ],
      authorize_address_prove_file_list: [
        {validator: testFile, required: true, message: 'pleas upload correct file', trigger: 'change'}
      ],
      authorize_passport_file_list: [
        {validator: testFile, required: true, message: 'pleas upload correct file', trigger: 'change'}
      ], 
      authorize_cn_identify_file_list: [
        {validator: testFile, required: true, message: 'pleas upload correct file', trigger: 'change'}
      ],
      
      authorize_nz_bank_card_front_file_list: [
        {validator: testFile, required: true, message: 'pleas upload correct file', trigger: 'change'}
      ],
      authorize_nz_bank_card_back_file_list: [
        {validator: testFile, required: true, message: 'pleas upload correct file', trigger: 'change'}
      ],
      authorize_nz_bank_statement_ird_file_list: [
        {validator: testFile, required: true, message: 'pleas upload correct file', trigger: 'change'}
      ],
      authorize_cn_identify_front_file_list: [
        {...fileValidator}
      ],
      authorize_cn_identify_back_file_list: [
        {...fileValidator}
      ],
      authorize_nz_driver_front_file_list: [
        {...fileValidator}
      ],
      authorize_nz_driver_back_file_list: [
        {...fileValidator}
      ],
    }
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      window.localStorage.setItem(payload.content, payload.value)
    },
    SetObjectMutaion (state, payload) {
      state[payload.content] = payload.value
      window.localStorage.setItem(payload.content, JSON.stringify(payload.value))
      // window.localStorage.setItem('token', '')
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ChangeObjectAction (content, payload) {
      content.commit('SetObjectMutaion', payload)
    }
  },
  getters: {
    GetStepThree: state => {
      if (state.has_authorize_other === true) {
        return state
      } else {
        return { has_authorize_other: state.has_authorize_other }
      }
    }
  }
}
export default personStepThree
