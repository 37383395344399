import axios from 'axios'
import store from '@/store'
import QS from 'qs'
import Router from '@/router'
// import { Message } from 'element-ui'
import { removeLocalStorage } from './localStorage'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'https://aus.iemoney.co.nz/'
} else if (process.env.NODE_ENV === 'debug') {
  axios.defaults.baseURL = 'https://www.ceshi.com'
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://aus.iemoney.co.nz/'
}
axios.defaults.timeout = 5000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

axios.interceptors.request.use(
  config => {
    const token = store?.state?.login?.token// 查看vuex里面是否有token
    if (token) {
      // console.log('hastoken')
      config.data = { ...config.data, token }
    } else {
      // console.log('localstorage dont have token')
    }
    if (config.method === 'post') {
      config.data = QS.stringify(config.data)
    }// POST请求body必须qs序列化 将token 存于data里面
    return config
  },
  error => {
    return Promise.error(error)
  }
)
axios.interceptors.response.use(
  // axios 响应拦截
  response => {
    if (response.status === 200) {
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response.data)
    }
  },
  error => {
    if (error.response.status) {
      // console.log(error.response)
      switch (error.response.status) {
        case 401:
          // Message({
          //   message: 'Unauthorized login',
          //   type: 'error'
          // })
          removeLocalStorage('token')
          removeLocalStorage('user')
          Router.replace('/login')
          // console.log('————未登入————')
          break
        case 'Expired token':
          // console.log('————登入过期————')
          break
        case 404:
          // console.log('————请求错误404————')
          break
        default :
          // console.log('————未知错误———')
      }
    }
    return Promise.reject(error.statusText)
  }
)
export function post (url, params) {
  // 项目所以请求都是post
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(
      res => {
        resolve(res)
      }
    ).catch(err => {
      reject(err)
    })
  })
}
export function _post (url, params) {
  // 项目所以请求都是post
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(
      res => {
        resolve(res)
      }
    ).catch(err => {
      reject(err)
    })
  })
}
