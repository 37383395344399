import { post } from '@/utils/request'

export const getRealtimeRateTable = (params = {}) => post('feed/realtimeRateTable')
export const getCustomerTransaction = () => post('feed/customerTransaction')

export const getCustomerDeposit = () => post('feed/customerDeposit')
export const getCustomerWithdrawal = () => post('feed/customerWithdrawal')
// belows api does not need token
export const getTaxCountry = () => post('tax-country')
export const getResidentialCountry = () => post('residential-country')
export const getPrimaryIdType = () => post('primary-id-type')
export const getIdIssueCountry = () => post('id-issue-country')
export const getNZVisaStatus = () => post('nz-visa-status')
export const getPurpostOfTrading = () => post('purpose-of-trading')
export const getSourceOfFunds = () => post('source-of-funds')
