import Vue from 'vue'
import { Button, message, DatePicker, Tabs, FormModel, Input, Switch, Radio, Checkbox, Select, Upload, Modal, Icon } from 'ant-design-vue'
Vue.use(Tabs)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Button)
Vue.use(DatePicker)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Upload)
Vue.use(Modal)
Vue.use(Icon)
Vue.use(Checkbox)

Vue.prototype.$message = message
